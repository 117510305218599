import React, {useEffect} from 'react';
import CKEditors from "react-ckeditor-component";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import '../../assets/scss/app-styles/qr-modal.scss'

const QRModal = (props) => {

    return(
        <>

            <Modal isOpen={props.show} toggle={props.toggleHandler}>
                <ModalHeader toggle={props.toggleHandler}>{"Email QR"}</ModalHeader>
                <ModalBody>
                    <form>

                        <div className="form-group">
                            <label className="col-form-label" htmlFor="message-text">{"To:"}</label>
                            <input type="text" onChange={props.onChangeHandler} className="form-control" name="to" value={props.to}/>

                            <label className="col-form-label" htmlFor="message-text">{"CC:"}</label>
                            <input type="text" onChange={props.onChangeHandler} className="form-control" name="cc" value={props.cc}/>

                            <label className="col-form-label" htmlFor="message-text">{"Subject:"}</label>
                            <input type="text" onChange={props.onChangeHandler} className="form-control" name="subject" value={props.subject}/>
                        </div>
                        

                        <div className="form-group">
                            {
                                props.showTemplateSave?
                                    <span className="template-add-btn" onClick={props.toggleTemplateSave}><i className="fa fa-minus-circle"/> </span>
                                :
                                    <span className="template-add-btn" onClick={props.toggleTemplateSave}><i className="fa fa-plus-circle"/> </span>
                            }
                            
                            {
                                props.showTemplateSave?
                                    <div className="row">
                                        <div className="col-md-8">
                                            <input type="text" onChange={props.onChangeHandler} placeholder="Template Name" 
                                                className="form-control" name="template_name" value={props.template_name} 
                                                disabled={props.templateSelected}/>
                                        </div>
                                        <div className="col-md-4">
                                            <Button className="template-save-btn" color="dark" onClick={props.saveTemplateHandler}>
                                                {props.templateSelected ? "Update" : "Save"}
                                            </Button>
                                        </div>
                                        
                                    </div>
                                : null
                            }
                            <label className="col-form-label" htmlFor="message-text">{"Body:"}</label>
                            <div className="qr-editor">

                                <CKEditors
                                    activeclassName="p10"
                                    content={props.body}
                                    events={{
                                        "change": props.editorOnChangeHandler
                                    }}
                                />

                            </div>

                            <div className="templates row">
                                {props.templatesList}
                            </div>
                            
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={props.sendEmailHandler}>{"Send Email"}</Button>
                    <Button color="secondary" onClick={props.toggleHandler}>{"Cancel"}</Button>
                </ModalFooter>
            </Modal>
        </>

    )};

export default QRModal;
