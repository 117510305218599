import React,{ Fragment } from 'react'
import {withRouter,Redirect} from "react-router";
import axios from 'axios';

import Spinner from '../../components/Widgets/Spinner';
import Logo from '../../assets/images/viirtue-logo.png';
import QRCode from "react-qr-code";

import '../../assets/scss/custom-tab-styles/style.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

class QrGenerator extends React.Component{

    state = {
        userName: "",
        password: "",
        cloud_id: "",
        loginValidationShow: false,
        toSignup: false,
        generating: false,
        qrModal: false,
        qrValue: '',
    }

    componentDidMount(){
        const username = this.props.location.search.substring(
            this.props.location.search.lastIndexOf("?username=") + 10, this.props.location.search.lastIndexOf("&cloud_id=")
        );
        let cloudId = this.props.location.search.substring(
            this.props.location.search.lastIndexOf("&cloud_id=") + 10, this.props.location.search.length
        );
        cloudId = cloudId.replace("*", "")
        this.setState({username: username});
        this.setState({cloudId: cloudId});
    }

    inputValueHandler = (evt) => {

            if(this.state.isAuthenticated)
            {
                this.setState({isAuthenticated: false});
            }
            this.setState({[evt.target.name]: evt.target.value});
            if(this.state.loginValidationShow){
                this.setState({loginValidationShow:false});
            }
    };

    qrCodeGenerator = (evt) => {
        evt.preventDefault();
        this.setState({generating: true});
        if(!this.state.username || !this.state.password )
        {
            this.setState({loginValidationShow:true});
            return;
        }
        const password = encodeURIComponent(this.state.password);
        this.setState({password: password});
        const payLoad = {
            user: this.state.username,
            password: password,
            cloud_id: this.state.cloudId
        }

        axios.post(`${process.env.REACT_APP_API_NS_URL}/signin`, payLoad)
        .then(response=>{
            
            this.setState({signinDomain: response.data.domain});
            this.setState({qrModal: true});
            //csc:username:password@CODE
            let qrValue = 'csc:' + this.state.username.split("@")[0] + ':';
            qrValue = qrValue + this.state.password;
            qrValue = qrValue + '@' + this.state.username.split("@")[1];
            this.setState({qrValue: qrValue});
            this.setState({cloudPassword: this.state.password});
            this.setState({password: ''});

        })
        .catch(err=>{
            this.setState({loginValidationShow: true});
            this.setState({generating: false});
            this.setState({isAuthenticated: false});
        });

    }

    sendEmail = (evt) => {
        evt.preventDefault();
        if(this.state.qrValue === ""){
            return;
        }
        this.setState({sendingEmail: true});
        const payLoad = new FormData();
        payLoad.append('user', this.state.username.split("@")[0]);
        payLoad.append('domain', this.state.signinDomain);
        payLoad.append('cloud_id', this.state.cloudId);
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=subscriber&action=read&utype=${'u'}&cloud_username=${this.state.username}&cloud_password=${this.state.cloudPassword}`, payLoad)
        .then(response=>{
            if(response.data[0]){
                const emailPayLoad = {
                    recepient: response.data[0].email.split(";")[0],
                    name: response.data[0].first_name + " " + response.data[0].last_name,
                    qrValue: this.state.qrValue
                };
        
                axios.post(`${process.env.REACT_APP_API_URL}emailQR`, emailPayLoad)
                .then(response=>{
                    toast.success("Sucessfully sent email");
                    this.setState({sendingEmail: false});
                })
                .catch(err=>{
                    toast.error("Unable to send email");
                    this.setState({sendingEmail: false});
                });

            }
            else{
                toast.error("No info on user found");
                this.setState({sendingEmail: false});
            }
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({sendingEmail: false});
        });
    }
    render(){
        
        return(
            <Fragment>
                <div className="page-wrapper">
                    <div className="auth-bg">
                        <div className="authentication-box">
                            <div className="text-center"><img width="300" src={Logo} alt="" /></div>
                            <div className="card mt-4">
                                <div className="card-body">
                                    
                                    <form className="theme-form"> 
                                        {
                                            this.state.qrValue === ""? 
                                            
                                                <div className="card-body text-center">
                                                    <h3 className="mb-4">QR Generator</h3>
                                                    <div className="input-group mb-3">
                                                        <input type="text" className="form-control" placeholder="Username" disabled
                                                        value={this.state.username} name="username" onChange={(evt) => this.inputValueHandler(evt)}/>
                                                    </div>
                                                    <div className="input-group mb-4">
                                                        <input type="password" className="form-control" placeholder="Password"
                                                        value={this.state.password} name="password" onChange={(evt) => this.inputValueHandler(evt)}/>
                                                    </div>

                                                    {
                                                        this.state.loginValidationShow?
                                                            <div className="mb-4">
                                                                <span className='login-error'>Invalid Password!</span>
                                                            </div>
                                                        :
                                                            null
                                                    }
                                                
                                                    <button className="btn btn-primary mb-4 qrCustomBtn" onClick = {this.qrCodeGenerator}>
                                                        {this.state.generating
                                                            ? <Spinner data="" />
                                                            : <FontAwesomeIcon icon={faQrcode} />
                                                        }
                                                    </button>

                                                </div>

                                            : 

                                                <div className="card-body text-center">
                                                    <h4>
                                                        Hi {this.state.username},<br/>
                                                    </h4>
                                                    <hr/>
                                                    <h5 className="mb-4">Please scan the below code to login</h5>
                                                    <div className="input-group mb-3" style={{display: "block"}}>
                                                        <QRCode value={this.state.qrValue}/>
                                                    </div>
                                                    <hr/>
                                                    <h4>Thank you for using our App.</h4>
                                                    <hr/>
                                                    <button className="btn btn-primary mb-4 qrCustomBtn" onClick = {this.sendEmail}>
                                                        {this.state.sendingEmail
                                                            ? <Spinner data="Sending..." />
                                                            : "Email QR Code"
                                                        }
                                                    </button>
                                                </div>

                                            
                                        }
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default QrGenerator;
