import React, { useState, useEffect, Fragment, useRef } from 'react';
import logo from '../assets/images/viirtue-app-logo.png';
import man from '../assets/images/dashboard/user.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router";
import QrReader from 'react-qr-scanner'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'

import '../assets/scss/app-styles/signin.scss'

import axios from "axios";
import { Authenticate, SignIn, Username, Password, CloudId, Passcode } from '../constant';

const AppSignin = ({ history }) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [cloudId, setCloudId] = useState("VIIRTUE");
    const [passcode, setPasscode] = useState("");
    const [mfa, setMfa] = useState({});
    const [build, setBuild] = useState('dev');
    const [delay, setDelay] = useState('100');
    const [showQr, setShowQR] = useState(false);
    const [result, setResult] = useState('No result');

    useEffect(()=> {
        const url = new URL(window.location.href);
        const myBuild = process.env.REACT_APP_BUILD;
        //const myCloudId = url.searchParams.get("cloud_id");
        setBuild(myBuild);
        //setCloudId(myCloudId);
    },[]);

    const appLogin = () => {
        // axios call here 
        //TODO:  also get profile image here of user and store it in profileURL
        if (username === "502@Peektest" && password === "hxq5pke-VDC2uxt3mcq") {

            window.location.href = "csc:502mi:AI7l4zWxL4h8@APPLE"
            return;
        }
        if (username === "503@Peektest" && password === "grb_fuh6rme6DPG!mqe") {
            window.location.href = "csc:503mi:pDEUwuOYQCyz@APPLE"
            return;
        }

        if (username === "505@Peektest" && password === "bew@dyg4UXW_ypw!fkh") {
            window.location.href = "csc:505ma:d9f4bc61@GOOGLE"
            return;
        }
        if (username === "504@Peektest" && password === "pcu9pbf3tpw3jgu_YMW") {
            window.location.href = "csc:504ma:b02913f6@GOOGLE"
            return;
        }
        const creds = {
            "cloud_id" : cloudId,
            "username" : username,
            "password" : password
        };
        axios.post(`${process.env.REACT_APP_API_URL}appSignin`,creds)
        .then(response =>{ 
            if(response.data.mfa === "mfa_required"){
                setMfa(response.data);
                return  

            } 
            let code = "csc:" + username + ":" + encodeURIComponent(password) + "@VIIRTUE"
            if (build === "dev") {
                code+= "*"
            }
            window.location.href = code

            //toast.success("Signing In Please Wait!");
        })
        .catch(err=>{
            toast.error("Failed to login");
            console.log(err);
        
        });
    
    }


    const goBack = () => {
        setMfa({});
    }

    const secondFactorAppLogin = () => {
        const payLoad = {
            "cloud_id" : cloudId,
            "username" : username,
            "password" : password,
            "passcode" : passcode,
            "mfa_type" : mfa.mfa_type,
            "mfa_vendor": mfa.mfa_vendor,
            "access_token": mfa.access_token,
        }
        axios.post(`${process.env.REACT_APP_API_URL}secondFactorAppSignin`,payLoad)
        .then(response =>{ 
            let code = "csc:" + username + ":" + password + "@VIIRTUE"
            if (build === "dev") {
                code+= "*"
            }
            window.location.href = code;
            //toast.success("Signing In Please Wait!");
        })
        .catch(err=>{
            toast.error("Failed to login");
            console.log(err);
        
        });
    }
    const previewStyle = {
        height: 240,
        width: 320,
    }
    const handleScan = (data) => {
        //setResult(data);
        if(data){
            const qrText = data.text;
            let qrUsername = qrText.split(":")[1];
            if(!qrUsername.includes("@")){
                qrUsername += "@" + qrText.split("@")[1];
            } 
            let qrPassword = qrText.split(":")[2];
            qrPassword = qrPassword.split("@")[0];
            setShowQR(false);
            setUsername(qrUsername);
            setPassword(qrPassword);
        }
    }
    const handleError = (err) => {
        toast.error(err);
    }
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="auth-bg" style={{background: "none"}}>
                    <div className="authentication-box">
                        <div className="text-center"><img width="300" src={logo} alt="" /></div>
                        <div className="card mt-4">
                            <div className="card-body">
                                <div className="text-center">
                                    <h4>{SignIn}</h4>
                                    <h6>
                                        { Object.keys(mfa).length === 0 ? 
                                            "Enter your Username and Password"
                                            :
                                            "Enter Passcode From Google Authenticator"
                                        } 
                                    </h6>
                                </div>
                                {
                                    Object.keys(mfa).length === 0?  

                                        <form className="theme-form"> 
                                            <div className="form-group">
                                                <label className="col-form-label pt-0">{Username}</label>
                                                <input className="form-control" type="text" name="username"
                                                    value={username}
                                                    onChange={e => setUsername(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label">{Password}</label>
                                                <input className="form-control" type="password" name="password"
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)} />
                                            </div>
                                            <div className="form-group form-row mt-3 mb-0">
                                                <button className="btn btn-block" 
                                                style={{backgroundColor: "#673490", borderColor: "#673490", color: "white"}}
                                                type="button" onClick={() => appLogin()} >{SignIn}</button>
                                            </div>

                                            {/* <div className="qr-div">
                                                {
                                                    showQr? 
                                                    <div>
                                                        <QrReader
                                                        delay={delay}
                                                        style={previewStyle}
                                                        onError={handleError}
                                                        onScan={handleScan}
                                                        />
                                                        <p onClick={e => setShowQR(false)} > Hide QR Scanner </p>
                                                    </div>
                                                    : 
                                                    <div>
                                                        <FontAwesomeIcon icon={faQrcode} onClick={e => setShowQR(true)} />
                                                        <p onClick={e => setShowQR(true)} > Scan QR Code </p>
                                                    </div>
                                                }
                                                
                                                
                                            </div> */}

                                        </form>
                                    : 
                                        <form className="theme-form"> 
                                            <div className="form-group">
                                                <label className="col-form-label pt-0">{Passcode}</label>
                                                <input className="form-control" type="text" name="passcode"
                                                    value={passcode}
                                                    onChange={e => setPasscode(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group form-row mt-3 mb-0">
                                                <button className="btn btn-block" 
                                                style={{backgroundColor: "#673490", borderColor: "#673490", color: "white"}}
                                                type="button" onClick={() => secondFactorAppLogin()} >{Authenticate}</button>
                                            </div>
                                            <p className="go-back" onClick={goBack}>Go Back</p>
                                        </form>
                                }

                            </div>
                            
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </Fragment>
    );
};

export default withRouter(AppSignin);