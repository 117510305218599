import React from 'react';
import axios from 'axios';
import moment from 'moment';

import Auxi from '../../hoc/Auxi';
import AppLayout from './CustomAppTabLayout';
import { toast } from 'react-toastify';


import '../../assets/scss/custom-tab-styles/style.scss';

class CallLogs extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            recordType: 'vmail/new',
            voiceMails: [],
            username: "Daniyal",
            loading: false,
            audioSource: '',
            showModal: false,
            data: {}

        };
        this.playPauseRef = [];
    }

    inputChangeHandler = (evt) => {

        const target = evt.target;
        const name = target.name;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        if (value === true) {
            value = "yes";
        }

        if (value === false) {
            value = "no";
        }
        

        let data = this.state.data;
        data[name] = value;
        this.setState({ data });
    }
    
    getDeviceInfo = (userName, uType, password, cloudId) => {
    
        const payLoad = new FormData();
        payLoad.append('device', "sip:" + userName.split("@")[0] + "@" + userName.split("@")[1]);
        payLoad.append('domain', userName.split("@")[1]);
        payLoad.append('cloudId', cloudId);
        
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=device&action=read&utype=${uType}&cloud_username=${userName}&cloud_password=${password}`, payLoad)
        .then(response=>{
            this.setState({username: (response.data[0].aor_user) +'@'+((response.data[0].sub_login).split("@")[1])});

        })
        .catch(err=>{
            toast.error(err.message);
        });
    }

    getCDRs = (uType, userName, domain, password, cloudId) =>{
        var x = new Date();
        const endDate = moment(x).format('YYYY-MM-DD');
        //x.setDate(1);
        x.setMonth(x.getMonth()-1);
        const startDate= moment(x).format('YYYY-MM-DD');
        console.log("START DATE: "+ startDate + " endndate: "+ endDate)
        const payLoad = new FormData();
        payLoad.append('uid', userName);
        payLoad.append('start_date', startDate);
        payLoad.append('end_date', endDate);
        payLoad.append('cloud_id', cloudId);
        payLoad.append('domain', domain);

        this.setState({loading: true});
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=cdr2&action=read&utype=${uType}&cloud_username=${userName}&cloud_password=${password}`, payLoad)
        .then(response=>{
            this.createCDRS(response.data);
            this.setState({loading: false});
        })
        .catch(err=>{
            toast.error(err.message,);
            this.setState({loading: false})
        });

    }
    
    createCDRS = (data) => {
        console.log("CREATUTN");

        const cdrs = data.map((row, key)=> {
            
            let icons = null;
            if(row.type === "0"){
                //outbound
                icons = <Auxi> 
                    <div className="call-status-top">
                        <i className="fas fa-arrow-up"/>
                    </div>
                    <div className="call-status-down">
                        <i className="fas fa-phone-alt"/>
                    </div>
                </Auxi>
            
            } else if (row.type === "1"){
                //inbound
                icons = <Auxi> 
                    <div className="call-status-top">
                        <i className="fas fa-arrow-down"/>
                    </div>
                    <div className="call-status-down">
                        <i className="fas fa-phone-alt"/>
                    </div>
                </Auxi>
            } else {
                //missed
                icons = <Auxi> 
                    <div className="call-status-top">
                    </div>
                    <div className="call-status-down">
                        <i className="fas fa-phone-slash"></i>
                    </div>
                </Auxi>
            }
            const date = moment.unix(row.time_start).toDate();
            const callDate = moment(date).local().format('DD/MM/YYYY, h:mm a');
            return (
                <Auxi>
                    <div className="call-logs-container">
                        {icons}
                        <div className="call-name">
                            {row.name ? row.name : row.number}
                        </div>
                        <div className="call-number">
                            {row.name ? row.number : null}
                        </div>
                        <div className="call-date">
                            {callDate}
                        </div>
                        <div className="call-duration">
                            {moment.utc(row.duration*1000).format('HH:mm:ss')}
                        </div>
                    </div>
                    <hr/>
                </Auxi>
            )
        });

        this.setState({cdrs: cdrs})
    }

    componentDidMount(){

        // infoOnExistingUser will provide the settings data

        let url = decodeURIComponent(window.location.href)

        url = url.substring(
            url.lastIndexOf("?username="),
                    url.length
                );
        this.setState({url: url});

                let username = url.substring(
            url.lastIndexOf("?username=") + 10,
                    url.lastIndexOf("&password=")
                );
        let password = url.substring(
            url.lastIndexOf("&password=") + 10,
            url.lastIndexOf("&cloud_id=")
        );
        let cloudId = url.substring(url.lastIndexOf("&cloud_id=") + 10,url.lastIndexOf("&direct="));
        let direct = url.substring(url.lastIndexOf("&direct=") + 8,url.length);
        cloudId = cloudId.replace("*", "");
        password = encodeURIComponent(password);
        
        this.setState({username: username});
        this.setState({password: password});
        this.setState({cloudId: cloudId});
        this.setState({direct: direct});
        const uType = 'u';
        const payLoad = {
            user: username,
            password: password,
            cloud_id: cloudId
        }

        axios.post(`${process.env.REACT_APP_API_NS_URL}/signin`, payLoad)
        .then(response=>{
            username =  username.split("@")[0] + "@" + response.data.domain;
            this.setState({username: username});
            this.setState({uid: response.data.uid});
            this.getCDRs('s', username, response.data.domain, password, cloudId);
            this.setState({uType: uType});
        })
        .catch(err=>{
            toast.error(err.message);
        });
        

    }


    render() {

        return(
            <Auxi>
                {
                    this.state.direct === "0" ?
                        <AppLayout cloud_id={this.state.cloudId}
                            display_name = {this.state.username}
                            ownContact = {this.state.ownContact}
                            fromPage = "callLogs"
                            url={this.state.url}/>
                    : null
                }

                <div className = "CustomMainIndexApp" style={{marginBottom: "10px"}}>
                    <div className="row voice-message-layout">
                        {this.state.cdrs? this.state.cdrs :null}
                        {/* <div className="call-logs-container">
                            <div className="call-status-top">
                                <i className="fas fa-arrow-down"/>
                            </div>
                            <div className="call-status-down">
                                <i className="fas fa-phone-alt"/>
                            </div>
                            <div className="call-name">
                                name
                            </div>
                            <div className="call-number">
                                number
                            </div>
                            <div className="call-date">
                                26/12/2021, 7:16 pm
                            </div>
                            <div className="call-duration">
                                00:00
                            </div>
                        </div>
                        <hr/>
                        <div className="call-logs-container">
                            <div className="call-status-top">
                                <i className="fas fa-arrow-up"/>
                            </div>
                            <div className="call-status-down">
                                <i className="fas fa-phone-alt"/>
                            </div>
                            <div className="call-name">
                                name
                            </div>
                            <div className="call-number">
                                number
                            </div>
                            <div className="call-date">
                                26/12/2021, 7:16 pm
                            </div>
                            <div className="call-duration">
                                00:00
                            </div>
                        </div>
                        <hr/>
                        <div className="call-logs-container">
                            <div className="call-status-top">
                            </div>
                            <div className="call-status-down">
                                <i className="fas fa-phone-slash"></i>
                            </div>
                            <div className="call-name">
                                name
                            </div>
                            <div className="call-number">
                                number
                            </div>
                            <div className="call-date">
                                26/12/2021, 7:16 pm
                            </div>
                            <div className="call-duration">
                                00:00
                            </div>
                        </div> */}

                    </div>
                </div>
            </Auxi>

        );

    }

}

export default CallLogs;
