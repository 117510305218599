import React from 'react'
import {Redirect} from 'react-router'

import Auxi from '../../hoc/Auxi'

import './Layout.css'

import { Navbar, Nav } from 'react-bootstrap' 
import { FlatStyle } from '../../constant'
//import Navbar from 'navbar-react'

class AppLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displaySettingsMenu: "None",
            userName: 'Daniyal',
            performLogout: false,
            openVoiceMails: false,
            showAnsweringRuleModal: false,
            openIndex: false,
            openVmailSettings: false,
            openCrexendoHD: false,
            openLandingPage: false,
            displaySecondSettingsMenu: "None",
        }
    }

    componentDidMount() {
        if (this.props.fromPage === "voiceMail"){
            document.getElementById("customVoiceMail").style.borderBottom="3px solid #203354";
        }
        if (this.props.fromPage === "voiceMailSettings"){
            document.getElementById("customTabUserSettings").style.borderBottom="3px solid #203354";
        }
        if (this.props.fromPage === "callLogs"){
            document.getElementById("customTabCallLogs").style.borderBottom="3px solid #203354";
        }
    }

    toggleSettingsHandler = (event) =>{
        event.preventDefault();
        if(this.state.displaySettingsMenu === "None"){
            this.setState({displaySettingsMenu: "block"});
        }
        else {
            this.setState({displaySettingsMenu: "None"});
        }
    }

    toggleSecondSettings = (evt) => {
            evt.preventDefault();
            if(this.state.displaySecondSettingsMenu === "None"){
            this.setState({displaySecondSettingsMenu: "flex"});
            }
            else {
            this.setState({displaySecondSettingsMenu: "None"});
            }
    }

    indexHandler =(event) => {
        event.preventDefault();
        this.setState({openLandingPage: true});
    }

    voiceHandler = (evt) => {
        evt.preventDefault();
        this.setState({openVoiceMails: true});
    }


    vmailSettingsHandler = (evt) => {
        evt.preventDefault();
        this.setState({openVmailSettings: true});
    }

    callLogsHandler = (evt) => {
        evt.preventDefault();
        this.setState({openCallLogs: true});
    }

    answeringRuleHandler = () => {
        this.setState({displaySettingsMenu: "None"});
        this.setState({showAnsweringRuleModal: true});
    }

    closeAnsweringRuleModal = () => {
        this.setState({showAnsweringRuleModal: false});
    }

    render(){

        let url = decodeURIComponent(window.location.href)

        url = url.substring(
            url.lastIndexOf("?username="),
                url.length
        );

        let redirect = null;
        const indexPath = "/custom_tab/index" + url;
        const voiceMailPath = "/custom_tab/voice_messages" + url;
        const vmailSettingsPath = "/custom_tab/vmail_settings" + url;
        const callLogsPath = "/custom_tab/call_logs" + url;

        if(this.state.openLandingPage){
            this.setState({openLandingPage: false});
            redirect = <Redirect to = {indexPath} />;
        }

        if(this.state.openVoiceMails){
            this.setState({openVoiceMails: false});
            redirect = <Redirect to = {voiceMailPath} />;
        }

        if(this.state.openVmailSettings){
            this.setState({openVmailSettings: false});
            redirect = <Redirect to ={vmailSettingsPath} />;
        }

        if(this.state.openCallLogs){
            this.setState({openCallLogs: false});
            redirect = <Redirect to ={callLogsPath} />;
        }

        return(
            <Auxi >
                {redirect}
                <div className = "app-layout">
                    

                    <div className="row custom-tab-row">
                    
                        <div id="customVoiceMail" className="col-4 custom-tab" onClick={this.voiceHandler}> 
                            <h3>Voice Mail</h3>
                        </div>
                        <div id="customTabUserSettings" className="col-4 custom-tab" onClick={this.vmailSettingsHandler}> 
                            <h3>Settings</h3>
                        </div>
                        <div id="customTabCallLogs" className="col-4 custom-tab" onClick={this.callLogsHandler}> 
                            <h3>Call Logs</h3>
                        </div>
                    </div>

                        
                    
                    

                </div>
            </Auxi>
        );
    }

}

export default AppLayout;
